import { ErrorBoundary, Header } from '@/molecules';
import { Footer } from '@/organisms';

import { ErrorPage } from './templates';

import type { ReactElement, ReactNode } from 'react';
import type { TypeHeaderProps } from './molecules/header';
import type { TypeFooterProps } from './organisms/footer';

export type TypePropsGetLayout = {
  children: ReactElement;
  header: TypeHeaderProps;
  footer: TypeFooterProps;
};

export const getLayout = ({ children, header, footer }: TypePropsGetLayout) => (
  <Layout header={header} footer={footer}>
    {children}
  </Layout>
);

const Layout = ({
  header,
  footer,
  children,
}: {
  header: TypeHeaderProps;
  footer: TypeFooterProps;
  children: ReactNode | ReactNode[];
}) => (
  <>
    <Header {...header} />
    <ErrorBoundary fallbackComponent={<ErrorPage statusCode={500} />}>
      <main>{children}</main>
    </ErrorBoundary>
    <Footer {...footer} />
  </>
);

export default Layout;
