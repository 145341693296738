import type { FunctionComponent } from 'react';
import type { TypeConditionalWrapperProps } from './types';

/**
 * ConditionalWrapper
 */
// TODO: Fix types
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const ConditionalWrapper: FunctionComponent<
  TypeConditionalWrapperProps
> = ({ condition, wrapper, children }: TypeConditionalWrapperProps) =>
  condition ? wrapper(children) : children;

ConditionalWrapper.displayName = 'ConditionalWrapper';
