import type { TypeTextColorVariant } from '@/components/types';
import type { FunctionComponent } from 'react';
import type { TypeCaptionProps } from './types';

const VARIANT_MAPS: Record<TypeTextColorVariant, string> = {
  primary: 'text-primary-400 fill-primary-400',
  secondary: 'text-secondary-400 fill-secondary-400',
  alternative: 'text-white fill-white',
};

/**
 * Caption
 */
export const Caption: FunctionComponent<TypeCaptionProps> = ({
  className = '',
  variant = 'primary',
  mobileAlignment = 'start',
  children,
  align = 'start',
}: TypeCaptionProps) => (
  <div
    className={`inline-flex w-full flex-row items-center ${
      align === 'start'
        ? 'md:justify-start'
        : align === 'center'
        ? 'md:justify-center'
        : 'md:justify-end'
    } ${
      mobileAlignment === 'start'
        ? 'justify-start'
        : mobileAlignment === 'center'
        ? 'justify-center'
        : 'justify-end'
    }`}
  >
    <p
      className={`text-caption text-caption--small items-center justify-center uppercase lg:text-caption--large ${VARIANT_MAPS[variant]} ${className}`}
    >
      {children}
    </p>
  </div>
);

Caption.displayName = 'Caption';
