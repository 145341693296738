import { getIcon } from '@/utils/getIcon';

import type { FunctionComponent } from 'react';
import type { TypeIconProps } from './types';

/**
 * Icon
 */
export const Icon: FunctionComponent<TypeIconProps> = ({
  className = '',
  height = 40,
  src,
  width = 20,
  style,
}: TypeIconProps) => {
  const Icon = getIcon(src);
  return (
    <Icon
      style={style}
      {...(width && { width: width })}
      {...(height && { height: height })}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      className={`transition-all duration-200 ${className}`}
    />
  );
};

Icon.displayName = 'Icon';
