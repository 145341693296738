import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import NextLink from 'next/link';
import { oneLine } from 'common-tags';

import {
  Button,
  Caption,
  Grid,
  // LanguagesSelect,
  Link,
  SocialMedia,
} from '@/atoms';

import CardCircleFilled from '@/assets/icons/CallCircleFilled.svg';
import LogoGreyStar from '@/assets/img/img/logoGreyStart2.svg';

import type { FunctionComponent } from 'react';
import type { TypeFooterProps } from './types';

/**
 * Footer
 */
export const Footer: FunctionComponent<TypeFooterProps> = ({
  className = '',
  followUs,
  linksColumn,
  menuType,
  pageConditionalProps,
  privacyPolitics,
  menuPage = 'Essential',
  logoBeCasaEssential,
}: TypeFooterProps) => {
  const { t } = useTranslation('common');

  return (
    <footer className={`${className} px-auto w-full bg-surface-09`}>
      <div className="max-w-wrapper flex flex-col gap-11 px-5 py-7 lg:m-auto">
        <div
          className={oneLine`
            flex items-center justify-between
            ${menuType === 'Default' ? 'flex-row ' : 'flex-row flex-wrap'}
          `}
        >
          <div className="relative h-[67px] w-[150px]  md:h-[95.7px] md:w-[214px]">
            <Image
              layout="fill"
              objectFit="contain"
              src={logoBeCasaEssential}
              alt="logoBeCasa"
            />
          </div>

          {menuType === 'Landing' ? (
            <div className="text-links text-links--default order-3 mt-10 flex flex-col justify-between gap-6 text-text-alternative sm:order-2  sm:mt-0 sm:flex-row sm:gap-10 ">
              {privacyPolitics?.map((child, index) => (
                <NextLink
                  href={child.src}
                  key={index}
                  passHref
                  prefetch={false}
                >
                  <Link aria-label="Enlace politicas" variant="alternative">
                    {child.label}
                  </Link>
                </NextLink>
              )) ?? null}
            </div>
          ) : null}

          {menuPage === 'Apartaments' && pageConditionalProps.button?.label && (
            <NextLink
              href={pageConditionalProps.button.src}
              prefetch={false}
              passHref
            >
              <Button
                size="small"
                as="a"
                variant={pageConditionalProps.button.variant}
                className={oneLine`
              text-links text-links--default hidden h-9 items-center text-text-alternative md:flex
              ${menuType === 'Landing' ? 'order-2 !flex sm:order-3' : ''}
            `}
              >
                {pageConditionalProps.button.label}
              </Button>
            </NextLink>
          )}
        </div>

        {menuType === 'Default' ? (
          <>
            <div
              className={`${
                menuPage === 'Essential' &&
                'flex flex-col gap-4 md:flex-row md:justify-between md:gap-0'
              }`}
            >
              <Grid xs={1} md={2} lg={4} className="flex flex-col !gap-4 ">
                {linksColumn?.map((item, index) => (
                  <Grid.Item key={index} className="flex flex-col gap-4 pr-4">
                    <div className="flex w-full flex-row items-baseline  gap-4">
                      <span className="text-subtitle text-subtitle--small flex flex-row whitespace-nowrap !font-bold text-text-alternative">
                        {item.columnTitle}
                      </span>
                      <hr className="block h-px w-full border-none bg-tertiary-100 text-text-alternative md:hidden" />
                    </div>

                    {item.links?.map((link, index2) => (
                      <div key={index2}>
                        <NextLink href={link.src} passHref prefetch={false}>
                          <Link
                            className="text-body text-body--primary !font-light !text-text-alternative"
                            variant="alternative"
                          >
                            {link.label}
                          </Link>
                        </NextLink>
                      </div>
                    ))}
                  </Grid.Item>
                ))}
              </Grid>
              {menuPage === 'Essential' && pageConditionalProps.helpUs && (
                <div className="pr-4">
                  <span className="text-subtitle text-subtitle--small mb-4 flex flex-row items-baseline gap-3 whitespace-nowrap !font-bold text-text-alternative">
                    {pageConditionalProps.helpUs.titleColumn}
                    <hr className="block h-px w-full border-none bg-tertiary-100 text-text-alternative md:hidden" />
                  </span>

                  <div className="flex flex-col gap-4">
                    {pageConditionalProps.helpUs.links.map(
                      (elemt: any, index: number) => (
                        <NextLink
                          aria-label="Faqs"
                          key={index}
                          href={elemt.src}
                          passHref
                          prefetch={false}
                        >
                          <Link
                            aria-label="Faqs"
                            variant="alternative"
                            className="text-body text-body--primary !font-light !text-text-alternative"
                          >
                            {elemt.label}
                          </Link>
                        </NextLink>
                      )
                    )}
                    <div>
                      <Link
                        href={`tel:${pageConditionalProps.helpUs.phoneNumber.replace(
                          /\s/g,
                          ''
                        )}`}
                        aria-label="Phone Number"
                        variant="alternative"
                        className="text-body text-body--primary !font-light !text-text-alternative"
                      >
                        {pageConditionalProps.helpUs.phoneNumber}
                      </Link>
                    </div>
                  </div>
                  {pageConditionalProps.helpUs.button.src && (
                    <NextLink
                      prefetch={false}
                      passHref
                      href={pageConditionalProps.helpUs.button.src}
                    >
                      <Button
                        className="mt-4"
                        as="a"
                        aria-label="Contactanos"
                        variant={pageConditionalProps.helpUs.button.variant}
                        size="large"
                      >
                        {pageConditionalProps.helpUs.button.label}
                      </Button>
                    </NextLink>
                  )}
                </div>
              )}
            </div>
            <div className="flex flex-col-reverse items-start gap-5 md:flex-row md:items-end md:justify-between">
              <div className="relative flex w-full flex-row justify-between border-primary-300 md:w-auto md:border-0">
                <div className="flex flex-col gap-6  md:flex-row md:gap-4 md:pt-0 lg:gap-10">
                  {/* <LanguagesSelect /> */}
                  {privacyPolitics?.map((child, index) => (
                    <NextLink
                      href={child.src}
                      key={index}
                      prefetch={false}
                      passHref
                    >
                      <Link
                        className={`text-links !text-links--small ${
                          index > 0
                            ? 'md:before:mr-2 md:before:h-4  md:before:w-px md:before:bg-primary-300 lg:before:mr-6'
                            : ''
                        } `}
                        variant="alternative"
                        aria-label={child.label}
                      >
                        {child.label}
                      </Link>
                    </NextLink>
                  )) ?? null}
                </div>

                {menuPage === 'Apartaments' && pageConditionalProps.button && (
                  <NextLink
                    href={pageConditionalProps.button.src}
                    prefetch={false}
                    passHref
                  >
                    <Button
                      className="text-links text-links--default h-9 self-end md:hidden"
                      as="a"
                      size="small"
                      variant="secondary"
                    >
                      {pageConditionalProps.button.label}
                    </Button>
                  </NextLink>
                )}
              </div>
              <hr className="block h-px w-full border-none bg-tertiary-100 text-text-alternative md:hidden" />
              <SocialMedia
                icons={followUs}
                iconSize={25}
                text={t('components.organisms.footer.followUs')}
                variants="alternative"
              />
              {menuPage === 'Apartaments' && (
                <div className="text-body text-body--primary flex flex-col gap-2 font-light text-primary-200">
                  {t('components.organisms.footer.callUs')}
                  <div className="text-links text-links--small flex flex-row items-center gap-2 font-light text-text-alternative">
                    <CardCircleFilled heigth={25} width={25} />
                    {pageConditionalProps.callUs?.phoneNumber}
                  </div>
                </div>
              )}
            </div>
          </>
        ) : null}
      </div>
      {menuType === 'Default' ? (
        <div className="relative h-9 w-full bg-[url(/assets/img/img/FooterPatter.svg)] bg-repeat-x" />
      ) : null}
      <div className="flex flex-col items-center justify-center gap-4 bg-surface-10 px-4 py-8 font-bold sm:flex-row">
        <div
          className={`flex flex-col justify-center ${
            menuPage === 'Apartaments' ? 'items-center' : 'items-end '
          }`}
        >
          <Caption
            className="text-other text-other--detail tracking-[0.2em]"
            variant="alternative"
          >
            {/* TODO: change to come from CMS */}
            {t('components.organisms.footer.beCasa')}
          </Caption>

          <span className="text-caption text-caption--small text-white">
            {t('components.organisms.footer.allRigths')}
          </span>
        </div>

        {menuPage === 'Essential' && (
          <>
            <div className="hidden h-6 w-px bg-primary-400 sm:block" />
            <LogoGreyStar alt="logoBeCasa" />
          </>
        )}
      </div>
    </footer>
  );
};

Footer.displayName = 'Footer';
