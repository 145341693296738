import { Icon } from '@/atoms';

import { domain } from '@/utils/utils';

import type { TypeDefinedColorsVariant } from '@/components/types';
import type { FunctionComponent } from 'react';
import type { TypeSocialMediaProps } from './types';

const variantsMapIcon: Record<TypeDefinedColorsVariant, string> = {
  alternative: 'text-white fill-transparent hover:fill-white ',
  brownie: 'text-brownie-400 fill-transparent hover:fill-brownie-400',
  mint: 'text-mint-400 fill-transparent hover:fill-mint-400',
  primary: 'text-primary-400 fill-transparent hover:fill-primary-400',
  secondary: 'text-secondary-400 fill-transparent hover:fill-secondary-400',
  tertiary: 'text-tertiary-400 fill-transparent hover:fill-tertiary-400',
  sky: 'text-sky-400 fill-transparent hover:fill-sky-400',
};

const variantsMapText: Record<TypeDefinedColorsVariant, string> = {
  alternative: 'text-white ',
  brownie: 'text-brownie-400',
  mint: 'text-mint-400 ',
  primary: 'text-primary-400 ',
  secondary: 'text-secondary-400',
  tertiary: 'text-tertiary-400',
  sky: 'text-sky-400',
};

/**
 * SocialMedia
 */
export const SocialMedia: FunctionComponent<TypeSocialMediaProps> = ({
  className = '',
  text,
  iconSize = 20,
  icons,
  variants,
}: TypeSocialMediaProps) => (
  <div className={`flex flex-col  justify-between gap-2 ${className}`}>
    {text && (
      <span
        className={`text-body text-body--primary ${variantsMapText[variants]}`}
      >
        {text}
      </span>
    )}
    <div className="flex flex-row items-center gap-5 text-transparent">
      {icons?.map((child, index) => (
        <a
          key={index}
          href={child.link}
          aria-label={domain(child.link)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon
            className={variantsMapIcon[variants]}
            src={child.src}
            height={iconSize}
            width={iconSize}
          />
        </a>
      )) ?? null}
    </div>
  </div>
);

SocialMedia.displayName = 'SocialMedia';
