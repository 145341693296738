import { oneLine } from 'common-tags';

import type { FunctionComponent } from 'react';
import type { TypeGridProps } from './types';

/**
 * Grid
 */
export const Grid: FunctionComponent<TypeGridProps> = ({
  className = '',
  style,
  outline = true,
  xs = 1,
  md = 2,
  lg = 3,
  refProp,
  children,
}: TypeGridProps) => (
  <div
    className={oneLine`
      grid grid-flow-row-dense
      ${outline ? 'gap-px' : ''} 
      grid-cols-${xs} 
      md:grid-cols-${md} 
      lg:grid-cols-${lg} 
      ${className}
    `}
    ref={refProp}
    style={style}
  >
    {children}
  </div>
);
