import { forwardRef } from 'react';
import { oneLine } from 'common-tags';

import Arrow from '@/assets/icons/ArrowLink.svg';

import type { TypeLinkTextColorVariant } from '@/templates/gallery-template/types';
import type { ComponentPropsWithRef, ElementType, Ref } from 'react';
import type { TypeLinkProps } from './types';

const VARIANT_MAPS: Record<TypeLinkTextColorVariant, string> = {
  primary:
    'text-state-primary-default hover:text-state-primary-hover disabled:text-state-primary-disabled aria-disabled:text-state-primary-disabled',
  secondary:
    'text-state-secondary-default hover:text-state-secondary-hover disabled:text-state-secondary-disabled aria-disabled:text-state-secondary-disabled',
  outline:
    'text-state-primary-default hover:text-state-primary-hover disabled:text-state-primary-disabled aria-disabled:text-state-primary-disabled px-10 py-3 gap-x-3 border rounded-full',
  alternative: 'text-white hover:opacity-70 disabled:opacity-20',
};

/**
 * Link
 */
const LinkBase = <T extends ElementType = 'a'>(
  {
    as,
    className = '',
    arrow = false,
    children,
    underline = false,
    variant,
    ...props
  }: TypeLinkProps<T> & Omit<ComponentPropsWithRef<T>, keyof TypeLinkProps<T>>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: Ref<any>
) => {
  const HTMLTag = as || 'a';
  return (
    <HTMLTag
      style={{ textUnderlineOffset: '0.25rem' }}
      className={oneLine`
        text-links text-links--default inline-flex cursor-pointer items-center gap-4 transition-all
        ${underline ? 'underline' : ''}
        ${VARIANT_MAPS[variant]}
        ${arrow ? 'hover:gap-5' : ''}
        ${className}
      `}
      ref={ref}
      {...props}
    >
      {children}
      {arrow && <Arrow width={20} heigth={14} />}
    </HTMLTag>
  );
};

LinkBase.displayName = 'Link';

export const Link = forwardRef(LinkBase);
