import { useEffect, useState } from 'react';
import { oneLine } from 'common-tags';

import { Multimedia } from '@/atoms';

import type { FunctionComponent } from 'react';
import type { TypeCardProps } from './types';

/**
 * Card
 */
export const Card: FunctionComponent<TypeCardProps> = ({
  backgroundColor,
  multimediaProps,
  className = '',
  children,
  orientation = 'column',
  rotated = false,
  multimediaHeith,
  width,
  showMultimediaInMobile = true,
  classNames,
}: TypeCardProps) => {
  const [orientationFinal, setOrientationFinal] = useState(orientation);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setOrientationFinal('column');
      } else {
        setOrientationFinal(orientation);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      style={{
        transition: 'transform 0.3s ease-out',
        transform: rotated ? 'rotateY(180deg)' : 'rotateY(0deg)',
        width: width ? `${width}px` : 'auto',
      }}
      className={`flex h-auto   ${
        orientationFinal === 'column'
          ? 'flex-col rounded-md'
          : 'flex-col rounded-l-md md:flex-row-reverse'
      }  shadow-lg  ${className} `}
    >
      {multimediaProps ? (
        <div
          style={{
            transition: 'transform 0.3s ease-out',
            transform: rotated ? 'rotateY(180deg)' : 'rotateY(0deg)',
            height:
              orientationFinal === 'row'
                ? 'auto'
                : multimediaHeith
                ? `${multimediaHeith}px`
                : '200px',
          }}
          className={`
            ${orientationFinal === 'row' ? 'w-full md:w-1/2' : 'w-full'}
            ${showMultimediaInMobile ? '' : 'hidden sm:block'}
          `}
        >
          <Multimedia
            heightType={orientationFinal === 'row' ? '%' : 'px'}
            heigth={
              orientationFinal === 'row'
                ? 100
                : multimediaHeith
                ? multimediaHeith
                : 200
            }
            {...multimediaProps}
            {...(classNames?.multimedia && {
              className: classNames.multimedia,
            })}
          />
        </div>
      ) : null}

      <div
        style={{
          transition: 'transform 0.3s ease-out',
          transform: rotated ? 'rotateY(180deg)' : 'rotateY(0deg)',
          backgroundColor: backgroundColor
            ? `var(--tw-color-${backgroundColor})`
            : '#fff',
        }}
        className={oneLine`
          h-full flex-grow overflow-hidden object-contain p-5
          ${
            multimediaProps
              ? orientationFinal === 'row'
                ? ' rounded-l-md'
                : ' rounded-b-md'
              : 'rounded-md'
          }
          ${classNames?.content ?? ''}
        `}
      >
        {children}
      </div>
    </div>
  );
};

Card.displayName = 'Card';
