import dynamic from 'next/dynamic';
import Image from 'next/image';

import type { FunctionComponent } from 'react';
import type { TypeGaleriaProps } from './types';

/**
 * Multimedia
 */

const Galeria = dynamic(() => import('./Galeria'), {
  ssr: true,
});

const Iframe = dynamic(() => import('./Iframe'), {
  ssr: true,
});

const ImageLogo = dynamic(() => import('./ImageLogo'), {
  ssr: true,
});

export const Multimedia: FunctionComponent<TypeGaleriaProps> = ({
  className = '',
  heigth = 0,
  image,
  imageBase,
  imageAbove,
  images,
  roundedPosition,
  src,
  popUp,
  popUpTitle,
  descPopUp,
  heightType = 'px',

  ...props
}: TypeGaleriaProps) => (
  <div
    style={
      heigth !== 0
        ? heightType === 'px'
          ? { height: `${heigth}px` }
          : { height: `${heigth}%` }
        : { height: 'auto' }
    }
    className={`flex w-full  ${className}`}
  >
    {images ? (
      <Galeria
        title={popUpTitle}
        desc={descPopUp}
        images={images}
        heigth={heigth}
        roundedPosition={roundedPosition}
        className="!h-full w-full"
      />
    ) : src ? (
      <Iframe
        descPopUp={descPopUp}
        popUpTitle={popUpTitle}
        popUp={popUp}
        {...props}
        src={src}
      />
    ) : imageAbove ? (
      <ImageLogo
        heightAbove={100}
        widthAbove={100}
        imageBase={imageBase}
        imageAbove={imageAbove}
      />
    ) : image ? (
      <div className="relative h-full w-full ">
        <Image
          className="rounded-t-md"
          src={image}
          objectFit="cover"
          layout="fill"
          alt="Image"
        />
      </div>
    ) : imageBase ? (
      <div className="relative h-full w-full ">
        <Image
          className="rounded-t-md "
          src={imageBase}
          objectFit="cover"
          layout="fill"
          alt="Image"
        />
      </div>
    ) : null}
  </div>
);

Multimedia.displayName = 'Multimedia';
