import { useEffect, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import NextLink from 'next/link';
import { oneLine } from 'common-tags';

import { Link as LinkCustom } from '@/atoms';
import { Card } from '@/molecules';

import { useRouteChangeStart } from '@/hooks/useRouteChangeStart';
import Grid from '../grid';
import Tabs from '../tabs';

import type { FunctionComponent } from 'react';
import type { TypeNavBarProps } from './types';

/**
 * NavBar
 */
export const NavBar: FunctionComponent<TypeNavBarProps> = ({
  className = '',
  menuContent,
  type,
}: TypeNavBarProps) => {
  const [open, setOpen] = useState(true);
  const tabsName = menuContent
    .map((content) => {
      if (content.megaMenuElement?.type === 'Locations') {
        return content.megaMenuElement?.locations?.map((loc) => loc.name);
      }
    })
    .filter(Boolean)
    .flat(2);
  const { t } = useTranslation('common');

  useRouteChangeStart(() => setOpen(false));
  useEffect(() => {
    setOpen(true);
  }, [open]);

  return (
    <nav
      className={`scrollbar-transparente flex flex-row overflow-auto pt-1 transition-colors duration-0 ${
        type !== 'Default'
          ? ' hover:scrollbar-secondary bg-transparent'
          : ' hover:scrollbar-primary bg-[#ECE3DF]'
      } ${className}`}
    >
      {menuContent.map((section, index) => (
        <div className={`${open ? 'group' : ''}  flex-shrink-0 `} key={index}>
          <NextLink href={section.element.src} passHref prefetch={false}>
            <a>
              <div
                className={oneLine`
            text-body text-body--primary--bold flex h-full cursor-pointer items-center border-b-2 px-5 text-center transition-colors duration-0
            ${
              type !== 'Default' && type !== 'StickyScrolled'
                ? 'border-transparent group-hover:border-white'
                : 'border-transparent text-primary-400 group-hover:border-primary-600 group-hover:text-primary-600'
            }
          `}
              >
                {section.element.label}
              </div>
            </a>
          </NextLink>
          {section.megaMenuElement && (
            <>
              <div className="absolute left-0 z-10 hidden h-1.5 w-full bg-transparent text-transparent group-hover:flex" />

              <div
                className={oneLine` 
            absolute left-0 hidden w-full bg-[#F9F1EC]  py-10 pb-8 !shadow-lg
            group-hover:flex
          `}
              >
                <div
                  className={`flex w-full px-5 md:max-w-wrapper md:mx-auto ${
                    section.megaMenuElement?.type === 'Locations'
                      ? ' flex-col gap-5'
                      : ''
                  }`}
                >
                  {section.megaMenuElement?.type === 'Locations' ? (
                    //eslint-disable-next-line
                    //@ts-ignore
                    <Tabs type="button" tabsName={tabsName}>
                      {section.megaMenuElement?.locations?.map(
                        (loc, index: number) => {
                          let numeroDeEdificios = 0;
                          loc.subLocations.forEach((subloc: any) => {
                            numeroDeEdificios += subloc.buildingLength;
                          });
                          return (
                            <div className="flex flex-col gap-8" key={index}>
                              <Grid className="!gap-8">
                                {loc.subLocations.map(
                                  (subLoc, subIndex: number) => (
                                    <Grid.Item key={subIndex}>
                                      {/* TODO: Cambiar el /es por el locale que este seleccionado */}
                                      <NextLink
                                        href={
                                          subLoc.buildingLength > 1
                                            ? `/es/donde-estamos/${loc.slug}/${subLoc.slug}`
                                            : `${subLoc.slug}`
                                        }
                                        passHref
                                        prefetch={false}
                                      >
                                        <a className="opacity-90 transition-all hover:opacity-100">
                                          <Card
                                            multimediaProps={{
                                              image: subLoc.locationImage,
                                              heigth: 10,
                                            }}
                                            classNames={{
                                              multimedia:
                                                'tall:!h-[200px] !h-32',
                                            }}
                                            className={`!h-48 text-text-primary  tall:!h-full tall:hover:bg-surface-01 `}
                                          >
                                            <div className="flex flex-col gap-2 ">
                                              {subLoc.name && (
                                                <span className="text-body text-body--primary--bold">
                                                  {subLoc.name}
                                                </span>
                                              )}
                                              {subLoc.description && (
                                                <span className="text-body text-body--secondary">
                                                  {subLoc.description}
                                                </span>
                                              )}
                                            </div>
                                          </Card>
                                        </a>
                                      </NextLink>
                                    </Grid.Item>
                                  )
                                )}
                              </Grid>
                              {loc.slug && (
                                <NextLink
                                  href={`/es/donde-estamos/${loc.slug}`}
                                  passHref
                                  prefetch={false}
                                >
                                  <LinkCustom variant="primary" underline>{`${t(
                                    'menus.linkLocation'
                                  )} ${
                                    loc.name
                                  } (${numeroDeEdificios})`}</LinkCustom>
                                </NextLink>
                              )}
                            </div>
                          );
                        }
                      )}
                    </Tabs>
                  ) : (
                    <div className="flex w-full flex-row">
                      <div className="w-2/3">
                        <Grid md={2} lg={2} className="!gap-10">
                          {section.megaMenuElement?.columns?.map(
                            (column, index) => (
                              <Grid.Item
                                className="flex flex-col gap-6"
                                key={index}
                              >
                                <div className="flex flex-col">
                                  <span className="text-subtitle text-subtitle--large">
                                    <NextLink
                                      href={column.title.src}
                                      passHref
                                      prefetch={false}
                                    >
                                      <a>{column.title.label}</a>
                                    </NextLink>
                                  </span>
                                  {column.subTitle && (
                                    <span className="text-text-primary">
                                      {column.subTitle}
                                    </span>
                                  )}
                                </div>
                                {column.links && (
                                  <div className="flex flex-col gap-2">
                                    {column.links.map(
                                      (link, subIndex: number) => (
                                        <NextLink
                                          key={subIndex}
                                          href={link.link.src}
                                          passHref
                                          prefetch={false}
                                        >
                                          <LinkCustom
                                            variant={link.link.variant}
                                          >
                                            <span className="!text-body !text-body--secondary font-normal">
                                              {link.link.label}
                                            </span>
                                          </LinkCustom>
                                        </NextLink>
                                      )
                                    )}
                                  </div>
                                )}
                              </Grid.Item>
                            )
                          )}
                        </Grid>
                      </div>
                      <div className="relative h-full w-1/3">
                        {section.megaMenuElement?.image && (
                          <Image
                            objectFit="cover"
                            layout="fill"
                            src={section.megaMenuElement.image}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      ))}
    </nav>
  );
};

NavBar.displayName = 'NavBar';
