import { Tab } from '@headlessui/react';
import { oneLine } from 'common-tags';

import { toArray } from '@/utils/utils';

import type { FunctionComponent } from 'react';
import type { TypeTabsProps, TypesTabs } from './types';

const TYPE_MAPS: Record<TypesTabs, string> = {
  default: `px-5 pt-2 pb-4 border-b-2`,
  button: 'px-4 py-1 border rounded-full',
};

/***
 * Tabs
 *
 * DoubleClick: Al hacer doble click se deja de mostrar y seleccionar ninguna de las tabs que hay en pantalla
 */
export const Tabs: FunctionComponent<TypeTabsProps> = ({
  children,
  tabsName,
  selectedAtFirst = 0,
  type = 'default',
}: TypeTabsProps) => (
  <Tab.Group defaultIndex={selectedAtFirst}>
    <Tab.List
      className={oneLine`
        scrollbar-transparente ${
          tabsName.length < 2 ? 'hidden' : ''
        } hover:scrollbar-primary flex flex-row overflow-auto
        ${type === 'button' ? 'flex flex-row gap-3 ' : ''}
      `}
    >
      {tabsName.map((child, index) => (
        <Tab
          className={({ selected }) => oneLine`
            text-body text-body--primary--bold flex-shrink-0 transition-colors focus:outline-none focus-visible:outline-none
            ${TYPE_MAPS[type]}
            ${
              child
                ? type === 'default'
                  ? selected
                    ? 'border-primary-600 text-primary-600'
                    : 'border-primary-300 text-primary-300'
                  : selected
                  ? 'border-primary-600 bg-primary-600 text-white'
                  : 'border-primary-300 text-primary-300'
                : 'cursor-default !border-none'
            }
          `}
          key={index}
        >
          {child}
        </Tab>
      ))}
    </Tab.List>

    <Tab.Panels>
      {toArray(children).map((child, index) => (
        <Tab.Panel unmount={false} key={index}>
          {child}
        </Tab.Panel>
      ))}
    </Tab.Panels>
  </Tab.Group>
);

Tabs.displayName = 'Tabs';
